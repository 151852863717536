import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

function padNumber(value: number) {
    if (isNumber(value)) {
        return `0${value}`.slice(-2);
    } else {
        return "";
    }
}

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
    return parseInt(`${value}`, 10);
}

@Injectable()
export class NgbDateStringAdapter extends NgbDateAdapter<string> {

    fromModel(value: string): NgbDateStruct
    {
        if (value) {
            const dateParts = value.trim().split('-');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return {year: toInteger(dateParts[0]), month: null, day: null};
            } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return {year: toInteger(dateParts[0]), month: toInteger(dateParts[1]), day: null};
            } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return {year: toInteger(dateParts[0]), month: toInteger(dateParts[1]), day: toInteger(dateParts[2])};
            }
        }

        return null;
        //return date ? {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() || 1} : null;
    }

    toModel(date: NgbDateStruct): string
    {
        let stringDate: string = ""; 
        if(date) {
            stringDate += date.year;
            stringDate += isNumber(date.month) ? "-" + padNumber(date.month) : "";
            stringDate += isNumber(date.day) ? "-" + padNumber(date.day) : "";
        }
        return stringDate;
        //return date ? new Date(date.year, date.month - 1, date.day, 0, 0, 0) : null;
    }

}