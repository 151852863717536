import { Pipe, PipeTransform } from '@angular/core';
import { VolumeService } from '../services';

@Pipe({
	name: 'volume',
	pure: false
})
export class VolumePipe implements PipeTransform {

	constructor(private volumeService: VolumeService) { }

	transform(id: number): string {
		if(!id) {
			return '';
		}

		let volume = this.volumeService.getItem(id);

		if(volume) {
			return volume.year + " (" + volume.number + ")";
		}

		return '';
	}
}
