import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { 
	TypeaheadSelectComponent, 
	EditableSelectComponent,
	MultiSelectChipsComponent,
	VolumesInputComponent
} from "./";
import { OnlyNumber } from '../../shared/directives/onlynumber.directive';

import {
	AutoCompleteModule
} from 'primeng/primeng';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	imports: [
	    CommonModule,
		FormsModule,
		NgbModule,
		AutoCompleteModule
	],
	declarations: [
	    TypeaheadSelectComponent,
		EditableSelectComponent,
		MultiSelectChipsComponent,
		VolumesInputComponent,
		OnlyNumber
	],
	exports: [
	    TypeaheadSelectComponent,
		EditableSelectComponent,
		MultiSelectChipsComponent,
		VolumesInputComponent,
		OnlyNumber
	]
})
export class SharedModule {}
