import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { UnderCategoryService, MainCategoryService } from '../../services/index';
import { UnderCategory, MainCategory } from '../../models/index';

import { MessageService } from 'primeng/components/common/messageservice';

/**
 * Content
 */

@Component({
	selector: 'app-under-category-modal-content',
	templateUrl: './under-category-modal.component.html',
	styleUrls: ['./under-category-modal.component.scss']
})
export class UnderCategoryModalContent {

	//@Input('name') name: string;
	//@Input('email') email: string;

	//@ViewChild('emailForm') public emailForm: NgForm;

	errors: Object = {};
	uiBlocked: boolean = false;

	form: UnderCategory = new UnderCategory;
	uploadSuccess: boolean = false;

    tagOptions: string[] = []; //SelectItem[] = [];

	mainCategory: MainCategory;

	constructor(
		public activeModal: NgbActiveModal,
		private underCategoryService: UnderCategoryService,
		private mainCategoryService: MainCategoryService,
		private messageService: MessageService
	) { }

	public onShow(main_category_id: number)
	{
		this.uiBlocked = true;
        
		this.form = new UnderCategory;
		this.form.main_category_id = main_category_id;

		this.mainCategoryService.getSingle(main_category_id).subscribe(response => {
			if(response.data) {
				this.mainCategory = response.data;
			} else {
				this.activeModal.close("error");
			}
			this.uiBlocked = false;
		}, errorResponse => {
			console.error(errorResponse);
			this.activeModal.close("error");
			this.uiBlocked = false;
		});

	}

	public onSubmit()
	{
		this.uiBlocked = true;

		this.underCategoryService.update(this.form, true).subscribe(response => {
			if(response.success) {
				this.errors = {};
				this.messageService.add({severity: 'success', summary: 'Under-categorie', detail: 'Under-categorie is opgeslagen!'});
				this.activeModal.close("success");
			} else {
				this.messageService.add({severity: 'error', summary: 'Under-categorie', detail: 'Under-categorie kon niet worden opgeslagen!'});
			}
			this.uiBlocked = false;
		}, errorResponse => {
			console.error(errorResponse);
			if(errorResponse.error && errorResponse.error.errors) {
				this.errors = errorResponse.error.errors;
			}
			this.uiBlocked = false;
		});
	}

}

/**
 * Modal 
 */

@Component({
	selector: 'app-under-category-modal',
	template: ``,
	//templateUrl: './upload-modal.component.html',
	//styleUrls: ['./upload-modal.component.scss'],
	animations: []
})
export class UnderCategoryModalComponent implements OnInit {

	private modalRef: NgbModalRef;

	constructor(private modalService: NgbModal) { }

	ngOnInit()
	{

	}

	public show(main_category_id: number)
	{
		//console.log(this.componentRef);

        //this.modalService.open(this.content, {
		this.modalRef = this.modalService.open(UnderCategoryModalContent ,{ 
            //size: 'lg',
            centered: true,
			ariaLabelledBy: 'modal-basic-title',
			backdrop: 'static'
		});
		
		this.modalRef.result.then((result) => {
            //this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});

		this.modalRef.componentInstance.onShow(main_category_id);
	}

	public hide()
	{
		this.modalRef.close();
	}

}