import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';

import { SelectItem, VolumeItem } from '../../models/index';


import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { VolumeService } from '../../services';

@Component({
    selector: 'volumes-input',
    templateUrl: './volumes-input.component.html',
    styleUrls: ['./volumes-input.component.scss']
})
export class VolumesInputComponent implements OnInit, OnDestroy {
	@Input()  required: boolean = false;
	@Input()  disabled: boolean = false;
	// @Input()  options: SelectItem[] = [];
	@Input()  placeholder: string = "";
	// @Input()  filterOn: string;
	// @Input()  showClear: boolean = false;
	@Input()  size: string = "md"; // sm, md, lg
	@Input()  iValue: VolumeItem[];
	@Output() iValueChange: EventEmitter<VolumeItem[]> = new EventEmitter();
	@Output() change: EventEmitter<number> = new EventEmitter();

	// lastSelected: VolumeItem;
    // selected: VolumeItem;
    
    selectedVolumes: VolumeItem[];

    //_options: SelectItem[];
    volumes: SelectItem[] = [];
    volumesSubscription: any;

	ngOnChanges(changes: SimpleChanges) {

		/*if(changes.options) {
            this._options = changes.options.currentValue;
		}*/

		if(changes.iValue) {
            this.selectedVolumes = changes.iValue.currentValue;
            if(this.selectedVolumes.length <= 0) {
                this.addVolume();
            }
			//this.selected = this._options.filter(v => v.value == changes.iValue.currentValue)[0];
			//this.lastSelected = this.selected;
		}

	}

    constructor(
        private volumeService: VolumeService
    ) { }

    ngOnInit(): void
    {
        this.volumes = this.volumeService.selectItemArray();
        this.volumesSubscription = this.volumeService.selectItemEvent.subscribe(items => {
            this.volumes = items;
        });
    }

    ngOnDestroy(): void
    {
        this.volumesSubscription.unsubscribe();
    }

	/*select(item: SelectItem) {
		this.selected = item;
		this.lastSelected = this.selected;
		this.iValueChange.emit(this.selected ? this.selected.value : null);
		this.change.emit(this.selected ? this.selected.value : null);
	}*/

    removeVolume(index)
    {
        this.selectedVolumes.splice(index, 1);
        if(this.selectedVolumes.length <= 0) {
            this.addVolume();
        }
    }

    addVolume()
    {
        this.selectedVolumes.push({
            id: 0,
            edition: 0
        });
    }
        
    trackByIndex(index: number, obj: any): any {
        return index;
    }
}
