import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { SettingsService } from './settings.service';
import { MainCategoryService } from './main-category.service';
import { UnderCategoryService } from './under-category.service';
import { SubCategoryService } from './sub-category.service';
import { PhotographerService } from './photographer.service';
import { TagService } from './tag.service';
import { VolumeService } from './volume.service';

import { User } from '../models/index';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class AuthenticationService {
	constructor(
		private http: HttpClient,
		private translate: TranslateService,
		private settingsService: SettingsService,
		private photographerService: PhotographerService,
		private mainCategoryService: MainCategoryService,
		private underCategoryService: UnderCategoryService,
		private subCategoryService: SubCategoryService,
		private tagService: TagService,
		private volumeService: VolumeService
	) {
        this.showLoaderChange.subscribe((value) => {
            this.showLoader = value
        });
	}

    setShowLoader(show: boolean) {
        this.showLoaderChange.next(show);
    }

	private needsCheck = true;
	private _user: User = null;
	get user():User {
		return this._user;
	}
	public loaded = true;

	showLoader: boolean;
	showLoaderChange: Subject<boolean> = new Subject<boolean>();

	login(email: string, password: string): Observable<LoginResponse> {
		return this.http.post<LoginResponse>('/api/v1/authenticate/login', {
			email: email,
			password: password
		}).pipe(map(data => {
			this._user = data.user;

			if(data.auth) {
				this.setLoggedIn();
			}

			return data;
		}));
	}

	isLoggedIn(): Observable<boolean> {
		console.log("isLoggedIn(): start");
		if(!this.needsCheck) {
			return of(!!localStorage.getItem('isLoggedin'));
		}

		console.log("isLoggedIn(): Loginstatus needs check");

		let getUser = !this._user;
		return this.http.post<LoginResponse>('/api/v1/authenticate/check', {
			getUser: getUser
		}).pipe(map(data => {
			if(getUser) {
				this._user = data.user;
			}

			this.needsCheck = false;
			setTimeout(()=>this.needsCheck=true, (5 * 60 * 1000));

			if(data.auth) {
				this.setLoggedIn();
			} else {
				this.setLoggedOut();
			}

			console.log("isLoggedIn(): returns " + (data.auth ? "true" : 'false'));

			return data.auth;
		}));
	}

	logout(): Observable<Object> {
		this.setLoggedOut();
		this._user = new User;
		let obs = this.http.get('/api/v1/authenticate/logout');
		obs.subscribe(() => {});
		return obs;
	}

	setLoggedOut() {
		localStorage.removeItem('isLoggedin');
		this.setShowLoader(true);
	}

	setLoggedIn() {
		localStorage.setItem('isLoggedin', 'true');

		this.setShowLoader(false);
		let groupedObservables = forkJoin(
			this.photographerService.loadItems(true),
			this.mainCategoryService.loadItems(true),
			this.underCategoryService.loadItems(true),
			this.subCategoryService.loadItems(true),
			this.tagService.loadItems(true),
			this.volumeService.loadItems(true),
			this.settingsService.loadSettings()
		);

		groupedObservables.subscribe(() => {
			this.setShowLoader(true);
		});
	}
}

interface LoginResponse {
	auth: boolean;
	user?: User;
	error?: string;
}
