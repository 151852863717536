import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LightboxModalComponent } from './lightbox-modal.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        NgbModule.forRoot()
    ],
    //entryComponents: [LightboxModalContent],
    declarations: [LightboxModalComponent], //, LightboxModalContent],
    exports: [LightboxModalComponent] //, LightboxModalContent]
})
export class LightboxModalModule { }
