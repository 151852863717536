import { Injectable } from '@angular/core';
import { ApiService, } from './api.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/index';

import { ConfirmationService } from 'primeng/primeng';
import { MessageService } from 'primeng/components/common/messageservice';

@Injectable()
export class UserService extends ApiService<User> {
	
	protected apiName: string = 'user';
	
	constructor(
		public http: HttpClient,
		public translate: TranslateService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService
	) {
		super(http, translate, confirmationService, messageService, User);
	}

	private usernames: Array<string> = [];

	getUserName(id: number): Observable<string> {
		if(this.usernames[id]) {
			return of(this.usernames[id]);
		}

		return this.http.get<LoginResponse>('/api/v1/user/name/' + id).pipe(map(data => {
			this.usernames[id] = data.name;
			return data.name;
		}));
	}
}

interface LoginResponse {
	name: string;
}
