import { Injectable } from '@angular/core';
import { ApiService, } from './api.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { Volume } from '../models/index';
import { SelectItem } from '../../shared/models/index';

import { ConfirmationService } from 'primeng/primeng';
import { MessageService } from 'primeng/components/common/messageservice';

import * as _ from 'lodash';

@Injectable()
export class VolumeService extends ApiService<Volume> {

	protected apiName: string = 'volume';
	protected autoLoadItems = true;

	protected loadItemsParameters = {
		sortField: 'number',
		sortOrder: 1
	};
	
	constructor(
		public http: HttpClient,
		public translate: TranslateService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService
	) {
		super(http, translate, confirmationService, messageService, Volume);
	}

	selectItemArray(items?: Volume[]): SelectItem[] {
		let array: SelectItem[] = [];

		if(!items) {
			items = this.items;
		}

		for(var item of items) {
			array.push({
				label: item.year + " (" + item.number + ")",
				value: item.id
			})
		}

		return array;
	}

}
