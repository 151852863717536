import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { SelectItem } from '../../models/index';

import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'typeahead-select',
    templateUrl: './typeahead-select.component.html',
    styleUrls: ['./typeahead-select.component.scss']
})
export class TypeaheadSelectComponent implements OnInit {
	/*@Input()  emptyOption: boolean = false;
	@Input()  emptyOptionText: string = "";*/
	@Input()  required: boolean = false;
	@Input()  disabled: boolean = false;
	@Input()  options: SelectItem[] = [];
	@Input()  placeholder: string = "";
	@Input()  filterOn: string;
	@Input()  showClear: boolean = false;
	@Input()  size: string = "md"; // sm, md, lg
	@Input()  iValue: number;
	@Output() iValueChange: EventEmitter<number> = new EventEmitter();
	@Output() change: EventEmitter<number> = new EventEmitter();

	lastSelected: SelectItem;
	selected: SelectItem;

	dropdownVisible: boolean = false;

	_options: SelectItem[];

	ngOnChanges(changes: SimpleChanges) {

		if(changes.options) {
			if(this.filterOn) {
				this._options = changes.options.currentValue.filter(v => (!v.filterable || v.filterable == this.filterOn));
			} else {
				this._options = changes.options.currentValue;
			}
		}

		if(changes.iValue) {
			this.selected = this._options.filter(v => v.value == changes.iValue.currentValue)[0];
			this.lastSelected = this.selected;
		}

		if(changes.filterOn) {
			this._options = this.options.filter(v => (!v.filterable || v.filterable == changes.filterOn.currentValue));

			//this.selected = this._options.filter(v => v.value == this.iValue)[0];
			//this.lastSelected = this.selected;
			var self = this;
			setTimeout(function() {
				self.select(self._options.filter(v => v.value == self.iValue)[0]);
			}, 1)
		}

	}

    constructor() { }

	ngOnInit() { }

	showDropdown() {
		this.dropdownVisible = true;
		if(this.dropdownTimer) {
			clearTimeout(this.dropdownTimer);
		}
	}

	dropdownTimer: any;

	hideDropdown() {
		this.dropdownTimer = setTimeout (() => {
			this.dropdownVisible = false;
			this.dropdownTimer = null;
		}, 1000);

	}

	onBlur() {
		if(!this.selected) {
			if(this.required) {
				this.selected = this.lastSelected; 
			}
		}
		
		if(this.selected != this.lastSelected) {
			this.select(null);
		}
	}

	select(item: SelectItem) {
		if(this.selected == item) return;
		this.selected = item;
		this.lastSelected = this.selected;
		this.iValueChange.emit(this.selected ? this.selected.value : null);
		this.change.emit(this.selected ? this.selected.value : null);
	}

	formatter = (x: SelectItem) => x.label;

	search = (text$: Observable<string>) =>
		text$
		.pipe(debounceTime(200))
		.pipe(distinctUntilChanged())
		.pipe(map(term => this._options.filter(v => v.label.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
		/*.map(term => term.length < 2 ? []
			: this._options.filter(v => v.label.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))*/;

}
