import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserPipe } from "./user.pipe";
import { PhotographerPipe } from "./photographer.pipe";
import { MainCategoryPipe } from "./main-category.pipe";
import { UnderCategoryPipe } from "./under-category.pipe";
import { SubCategoryPipe } from "./sub-category.pipe";
import { VolumePipe } from './volume.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
		UserPipe,
		PhotographerPipe,
		MainCategoryPipe,
		UnderCategoryPipe,
		SubCategoryPipe,
		VolumePipe
	],
	exports:[
		UserPipe,
		PhotographerPipe,
		MainCategoryPipe,
		UnderCategoryPipe,
		SubCategoryPipe,
		VolumePipe
	]
})
export class SharedPipesModule { }
