import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'editable-select',
    templateUrl: './editable-select.component.html',
    styleUrls: ['./editable-select.component.scss']
})
export class EditableSelectComponent implements OnInit {
	/*@Input()  emptyOption: boolean = false;
	@Input()  emptyOptionText: string = "";*/
	@Input()  required: boolean = false;
	@Input()  disabled: boolean = false;
	@Input()  options: string[] = [];
	@Input()  placeholder: string = "";
	@Input()  filterOn: string;
	@Input()  iValue: string;
    @Output() iValueChange: EventEmitter<string> = new EventEmitter();

	/*@Input() set iValue(value: number) {
		if(value) {
			this.selected = this._options.filter(v => v.value == value)[0]; //this.iValue
			this.lastSelected = this.selected;
		}
	}

	@Input() set filterOn(value: string) {
		if(value) {
			this._options = this.options.filter(v => (!v.filterable || v.filterable == value));

			this.selected = this._options.filter(v => v.value == this.iValue)[0];
			this.lastSelected = this.selected;
		}
	}*/

	lastSelected: string;
	selected: string;

	_options: string[];

	ngOnChanges(changes: SimpleChanges)
	{

		if(changes.options) {
			this._options = changes.options.currentValue;
		}

		if(changes.iValue) {
			this.selected = changes.iValue.currentValue;//this._options.filter(v => v == changes.iValue.currentValue)[0];
			this.lastSelected = this.selected;
		}

		/*if(changes.filterOn) {
			this._options = this.options.filter(v => (!v.filterable || v.filterable == changes.filterOn.currentValue));

			this.selected = this._options.filter(v => v.value == this.iValue)[0];
			this.lastSelected = this.selected;
		}*/

	}

    constructor()
	{
	}

    ngOnInit()
	{
	}

	onChange()
	{
		this.select(this.selected);
		//this.lastSelected = this.selected;
		//this.iValueChange.emit(this.selected);
		/*if(!this.selected) {
			this.selected = this.lastSelected;
		}*/
	}

	select(text: string)
	{
		this.selected = text;
		this.lastSelected = this.selected;
		this.iValueChange.emit(this.selected);
	}

	formatter = (x: string) => x;

	search = (text$: Observable<string>) =>
		text$
		.pipe(debounceTime(200))
		.pipe(distinctUntilChanged())
		.pipe(map(term => this._options.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)))
		/*.map(term => term.length < 2 ? []
			: this._options.filter(v => v.label.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))*/;

}
