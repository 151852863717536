import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule }   from '@angular/forms';
import {
	BlockUIModule,
	EditorModule,
	DialogModule,
	AutoCompleteModule
} from 'primeng/primeng';
import { CKEditorModule } from 'ng2-ckeditor';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

import { SubCategoryModalComponent, SubCategoryModalContent } from './sub-category-modal.component';
import { SharedModule } from '../../components/shared.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,
        NgbModule.forRoot(),
        BlockUIModule,
        EditorModule,
        DialogModule,
        AutoCompleteModule,
        FormsModule,
        CKEditorModule,
        DropzoneModule,
        SharedModule
    ],
    entryComponents: [SubCategoryModalContent],
    declarations: [SubCategoryModalComponent, SubCategoryModalContent],
    exports: [SubCategoryModalComponent, SubCategoryModalContent]
})
export class SubCategoryModalModule { }
