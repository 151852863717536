import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewEncapsulation  } from '@angular/core';

import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/map';

@Component({
    selector: 'multi-select-chips',
    templateUrl: './multi-select-chips.component.html',
	styleUrls: ['./multi-select-chips.component.scss'],
	encapsulation: ViewEncapsulation.None  
})
export class MultiSelectChipsComponent implements OnInit {
	@Input()  required: boolean = false;
	@Input()  disabled: boolean = false;
	@Input()  options: string[] = [];
	@Input()  placeholder: string = "";
	//@Input()  filterOn: string;
	@Input()  AllowNew: boolean = true;
	@Input()  iValue: string[];
	@Input()  size: string = 'md';
	@Output() iValueChange: EventEmitter<string[]> = new EventEmitter();
	@Output() change: EventEmitter<string[]> = new EventEmitter();

	_options: string[];
	_filteredOptions: string[];
	selected: string[];

	ngOnChanges(changes: SimpleChanges)
	{

		if(changes.options) {
			this._options = changes.options.currentValue;
		}

		if(changes.iValue) {
			this.selected = changes.iValue.currentValue;
		}

	}

    constructor()
	{
	}

    ngOnInit()
	{
	}

	onChange()
	{
		this.iValueChange.emit(this.selected);
		this.change.emit(this.selected);
	}

	
	filterOptions(event) {
		let query = event.query.trim();

        if(this.AllowNew && this._options.filter(v => v == query).length <= 0 && this.selected.filter(v => v == query).length <= 0) {
            this._filteredOptions = [query];
        } else {
            this._filteredOptions = [];
        }

        this._filteredOptions = this._filteredOptions.concat(
            this._options.filter(
				v => v.toLowerCase().indexOf(query.toLowerCase()) > -1 && this.selected.filter(vv => vv == v).length <= 0
			).slice(0, 10)
        );
    }
    
    onKeydown(event) {
        if(this.AllowNew && event.key === "Enter") {
            if(event.target.value != "") { // this._filteredOptions.length > 0 &&
				var val = event.target.value;
				event.target.value = "";
				if(this.selected.filter(v => v == val).length <= 0) {
					this.selected.push(val); //this._filteredOptions.shift());
					this.iValueChange.emit(this.selected);
					this.change.emit(this.selected);
				}
            }
        }
    }

	/*
	
	filterOptions(event) {
		let query = event.query.trim();

        if(this._options.filter(v => v.label == query).length <= 0 && this.selected.filter(v => v.label == query).length <= 0) {
            this._filteredOptions = [{
                label: query,
                value: query
            }];
        } else {
            this._filteredOptions = [];
        }

        this._filteredOptions = this._filteredOptions.concat(
            this._options.filter(
				v => v.label.toLowerCase().indexOf(query.toLowerCase()) > -1 && this.selected.filter(vv => vv.label == v.label).length <= 0
			).slice(0, 10)
        );
    }
    
    onKeydown(event) {
        if(event.key === "Enter") {
            if(event.target.value != "") { // this._filteredOptions.length > 0 &&
				var val = event.target.value;
				event.target.value = "";
				if(this.selected.filter(v => v.label == val).length <= 0) {
					this.selected.push({
						label: val,
						value: val
					}); //this._filteredOptions.shift());
					this.iValueChange.emit(this.selected);
				}
            }
        }
    }*/

}
