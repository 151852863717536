import { Pipe, PipeTransform } from '@angular/core';
import { SubCategoryService } from '../services';

@Pipe({
	name: 'subcategory',
	pure: false
})
export class SubCategoryPipe implements PipeTransform {

	constructor(private subCategoryService: SubCategoryService) { }

	transform(id: number): string {
		if(!id) {
			return '';
		}

		let subcat = this.subCategoryService.getItem(id);

		if(subcat) {
			return subcat.name;
		}

		return '';
	}
}
