import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '../services/index';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(
		private router: Router,
		private authService: AuthenticationService) {
	}

	canActivate(): Observable<boolean> {
		return this.authService.isLoggedIn().pipe(map(auth => {
            if(auth) {
                let user = this.authService.user;
                if(user && user.admin) {
                    return auth;
                }
            }

            this.router.navigate(['/login']);
            return false;
		}));
    }
    
}
