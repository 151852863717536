export class Settings {
	id: number;
	base_url: string;
	offer_footer_text: string;
	default_classification: number;
	default_pricelist: number;
	
    constructor() {
		this.id = 1;
	}
}
