import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services';

@Pipe({
	name: 'user',
	pure: false
})
export class UserPipe implements PipeTransform {
	private cachedData: string = null;
    private cachedId = 0;

	constructor(private userService: UserService) { }

	transform(id: number): string {
		if(!id) {
			return '';
		}
		if(id != this.cachedId) {
			this.cachedData = null;
			this.cachedId = id;
			this.userService.getUserName(id).subscribe(username => {
				this.cachedData = username;
			})
		}

		return this.cachedData;
	}
}
