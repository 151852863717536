import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import {
	TagService, 
    PhotographerService
} from '../../services/index';
import { Photographer } from '../../models/index';

import { MessageService } from 'primeng/components/common/messageservice';
import { CookieService } from 'ngx-cookie-service';

/**
 * Content
 */

@Component({
	selector: 'app-photographer-modal-content',
	templateUrl: './photographer-modal.component.html',
	styleUrls: ['./photographer-modal.component.scss']
})
export class PhotographerModalContent {

	//@Input('name') name: string;
	//@Input('email') email: string;

	//@ViewChild('emailForm') public emailForm: NgForm;

	errors: Object = {};
	uiBlocked: boolean = false;

	form: Photographer = new Photographer;
	uploadSuccess: boolean = false;

    tagOptions: string[] = []; //SelectItem[] = [];

	constructor(
		public activeModal: NgbActiveModal,
		private tagService: TagService,
		private photographerService: PhotographerService,
		private messageService: MessageService,
		private cookieService: CookieService
	) { }

	public onShow()
	{
        
        this.form = new Photographer;

	}

	public onSubmit()
	{
		this.uiBlocked = true;

		this.photographerService.update(this.form, true).subscribe(response => {
			if(response.success) {
				this.errors = {};
				this.messageService.add({severity: 'success', summary: 'Foto', detail: 'Foto is opgeslagen!'});
				this.activeModal.close("success");
			} else {
				this.messageService.add({severity: 'error', summary: 'Foto', detail: 'Foto kon niet worden opgeslagen!'});
			}
			this.uiBlocked = false;
		}, errorResponse => {
			if(errorResponse.error && errorResponse.error.errors) {
				this.errors = errorResponse.error.errors;
			}
			this.uiBlocked = false;
		});
	}

}

/**
 * Modal 
 */

@Component({
	selector: 'app-photographer-modal',
	template: ``,
	//templateUrl: './upload-modal.component.html',
	//styleUrls: ['./upload-modal.component.scss'],
	animations: []
})
export class PhotographerModalComponent implements OnInit {

	private modalRef: NgbModalRef;

	constructor(private modalService: NgbModal) { }

	ngOnInit()
	{

	}

	public show()
	{
		//console.log(this.componentRef);

        //this.modalService.open(this.content, {
		this.modalRef = this.modalService.open(PhotographerModalContent ,{ 
            //size: 'lg',
            centered: true,
			ariaLabelledBy: 'modal-basic-title',
			backdrop: 'static'
		});
		
		this.modalRef.result.then((result) => {
            //this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});

		this.modalRef.componentInstance.onShow();
	}

	public hide()
	{
		this.modalRef.close();
	}

}