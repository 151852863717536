import { Pipe, PipeTransform } from '@angular/core';
import { UnderCategoryService } from '../services';

@Pipe({
	name: 'undercategory',
	pure: false
})
export class UnderCategoryPipe implements PipeTransform {

	constructor(private underCategoryService: UnderCategoryService) { }

	transform(id: number): string {
		if(!id) {
			return '';
		}

		let undercat = this.underCategoryService.getItem(id);

		if(undercat) {
			return undercat.name;
		}

		return '';
	}
}
