import { Injectable } from '@angular/core';
import { ApiService, } from './api.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/map'

import { UnderCategory } from '../models/index';
import { SelectItem } from '../../shared/models/index';

import { ConfirmationService } from 'primeng/primeng';
import { MessageService } from 'primeng/components/common/messageservice';

@Injectable()
export class UnderCategoryService extends ApiService<UnderCategory> {

	protected apiName: string = 'under-category';
	protected autoLoadItems = true;

	protected loadItemsParameters = {
		sortField: ['sort_order = 9999', 'name'],
		sortOrder: [1, 0]
	};

	constructor(
		public http: HttpClient,
		public translate: TranslateService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService
	) {
		super(http, translate, confirmationService, messageService, UnderCategory);
	}

	selectItemArray(): SelectItem[] {
		let array: SelectItem[] = [];

		for(var item of this.items) {
			array.push({
				label: item.name,
				value: item.id,
				filterable: "" + item.main_category_id
			})
		}

		return array;
	}

}
