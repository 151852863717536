import { Component, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Content } from '@angular/compiler/src/render3/r3_ast';

@Component({
    selector: 'app-lightbox-modal',
    templateUrl: './lightbox-modal.component.html',
    styleUrls: ['./lightbox-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LightboxModalComponent {
    @ViewChild('content') content: ElementRef;

    private image: string = '';

    constructor(private modalService: NgbModal) { }

    open(image: string)
    {
        this.image = image;
        
        this.modalService.open(this.content, {
			ariaLabelledBy: 'modal-basic-title',
            windowClass: 'image-modal'
        }).result.then((result) => {
            //this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
}
