import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { retry } from 'rxjs/operators';

import { LazyLoadEvent, ConfirmationService, FilterMetadata } from 'primeng/primeng';
import { MessageService } from 'primeng/components/common/messageservice';

import { SelectItem } from '../../shared/models/index';

import * as _ from 'lodash';

@Injectable()
export abstract class ApiService<T extends serviceModel> {

	protected readonly apiVersion: string = 'v1';
	protected abstract apiName: string = '';
	protected retries = 0; //2;
	protected autoLoadItems = false;

	protected items: Array<T> = [];
	protected keyedItems: {[k: number]: T} = {};

	protected loadItemsParameters: object = {
					sortField: 'id',
					sortOrder: 0
				}

	public getBaseUrl(action?: string, parameters?: Array<string> | string) {
		let url = '/api/' + this.apiVersion + '/' + this.apiName;
		if(action) {
			url += '/' + action;
		}
		if(parameters) {
			parameters = (Array.isArray(parameters) ? parameters : [parameters]);
			for(let p of parameters) {
				url += '/' + p;
			}
		}
		return url;
	}

	constructor(
		public http: HttpClient,
		public translate: TranslateService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public typeClass: new () => T
	) {}

	loadItems(reload: boolean = false): Observable<Array<T>> {
		if(!reload && this.items.length > 0) {
			return  Observable.create(observer => {
				observer.next(this.items);
				observer.complete();
			});
		}
		return this.getAll(this.loadItemsParameters).pipe(map(data => {
			this.items = data.data;
			this.keyedItems = _.keyBy(data.data, item => +item.id)
			this.selectItemEvent.next(this.selectItemArray());

			return this.items;
		}));
	}

	getItem(id: number): T {
		return this.keyedItems[id];
	}

	get(params: getParameters): Observable<getResponse<T>> {
		var self = this;
		let postParams: {[k: string]: any} = {
			first: 0,
			rows: 10,
			sortField: '',
			sortOrder: 0,
			filters: {},
			globalFilter: {}
		};

		if(params.pagination) {
			//postParams.first = (params.pagination.page - 1) * params.pagination.perPage;
			postParams.first = params.pagination.first;
			postParams.page = params.pagination.page;
			postParams.rows = params.pagination.rows;
			postParams.sortField = params.pagination.sortField;
			postParams.sortOrder = (params.pagination.sortOrder ? 1 : 0);
		}

		if(params.filters) {
			postParams.filters = params.filters;
		}

		if(params.search) {
			postParams.globalFilter = { value: params.search };
		}
		
		if(params.fields) {
			postParams.fields = params.fields;
		}
		
		return this.http.post<getResponse<T>>(
			this.getBaseUrl('get'),
			postParams
		).pipe(retry(this.retries)).pipe(map(data => {

			if(data.data) {
				data.data = data.data.map(item => Object.assign(new self.typeClass, item));
			}

			return data;
		}));
	}

	/**
	 * getAll(parameters)
	 * Retrieve list of objects
	 */
	getAll(parameters: object = {}): Observable<getResponse<T>> {
		return this.http.post<getResponse<T>>(
			this.getBaseUrl('get'),
			parameters
		).pipe(retry(this.retries));
	}

	/**
	 * getLazy(event: LazyLoadEvent, fields?: Array<string>)
	 */
	getLazy(event: LazyLoadEvent, fields?: Array<string>): Observable<getResponse<T>> {
		var self = this;
		let params: {[k: string]: any} = event;
		if(fields) {
			params.fields = fields;
		}
		return this.http.post<getResponse<T>>(
			this.getBaseUrl('get'),
			params
		).pipe(retry(this.retries)).pipe(map(data => {

			if(data.data) {
				data.data = data.data.map(item => Object.assign(new self.typeClass, item));
			}

			return data;
		}));
	}

	/**
	 * getSingle(id)
	 * Retrieve single object
	 */
	getSingle(id: number, params?: any): Observable<getSingleResponse<T>> {
		var self = this;
		return this.http.get<getSingleResponse<T>>(
			this.getBaseUrl('get', id.toString()),
			{
				params: params
			}
		)
		.pipe(retry(this.retries))
		.pipe(map(data => {
			
			data.data = Object.assign(new self.typeClass, data.data);

			return data;
		}));
	}

	/**
	 *
	 */
	update(data: T, store: boolean = false): Observable<updateResponse<T>> {
		return this.http.post<updateResponse<T>>(
			this.getBaseUrl(store ? 'store' : 'update'),
			data
		)
		.pipe(map(data => {

			if(this.autoLoadItems) {
				this.loadItems(true).subscribe(response => {});
			}

			return data;
		}));
	}

	/**
	 * delete(id)
	 */
	delete(id: number): Observable<deleteResponse> {
		return  Observable.create(observer => {
			this.confirmationService.confirm({
				message: this.translate.instant("Are you sure?"),
				header: this.translate.instant("Delete " + this.apiName),
				icon: 'fa fa-trash',
				accept: () => {
					this.http.delete<deleteResponse>(
						this.getBaseUrl(id.toString())
					).subscribe(response => {
						if(response.success) {
							this.messageService.add({severity: 'success', summary: this.translate.instant("Delete " + this.apiName), detail: this.translate.instant(this.apiName + " successfully deleted")});
						} else {
							let error = (response.error ? response.error : this.translate.instant("Unknown error occured"));
							this.messageService.add({severity: 'danger', summary: this.translate.instant("Delete " + this.apiName), detail: error});
						}

						observer.next(response);
						observer.complete();
					}, err => {
						observer.next({
							success: false,
							error: ''
						});
						observer.complete();
					});
				},
				reject: () => {}
			});
		});
	}

	/**
	 *
	 */
	
    selectItemEvent: EventEmitter<SelectItem[]> = new EventEmitter();

	selectItemArray(items?: T[]): SelectItem[] {
		return [];
	}

}


export interface getResponse<T> {
	data: Array<T>;
	pagination: pagination;
}

export interface getSingleResponse<T> {
	data: T;
}

export interface updateResponse<T> {
	success: boolean;
	error: string;
	errors: Object;
	data: T;
}

export interface deleteResponse {
	success: boolean;
	error: string;
}

export interface getParameters {
	pagination?: pagination, 
	filters?: { [s: string]: FilterMetadata; }, 
	search?: string,
	fields?: Array<string>
}

export interface pagination {
	page: number;
	first?: number;
	rows: number;
	totalRows?: number;
	totalPages?: number;
	sortField: string;
	sortOrder: boolean;
}

interface serviceModel {
	id: number;
}

/*interface getResponse<T> {
	success: boolean;
	count: number;
	results: Array<T>;
}

interface getSingleResponse<T> {
	success: boolean;
	result: T;
}*/

/*
	/load
	/loadSingle
	/update
	/add
	/delete

*/
