import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { SubCategoryService, UnderCategoryService } from '../../services/index';
import { SubCategory, UnderCategory } from '../../models/index';

import { MessageService } from 'primeng/components/common/messageservice';

/**
 * Content
 */

@Component({
	selector: 'app-sub-category-modal-content',
	templateUrl: './sub-category-modal.component.html',
	styleUrls: ['./sub-category-modal.component.scss']
})
export class SubCategoryModalContent {

	//@Input('name') name: string;
	//@Input('email') email: string;

	//@ViewChild('emailForm') public emailForm: NgForm;

	errors: Object = {};
	uiBlocked: boolean = false;

	form: SubCategory = new SubCategory;
	uploadSuccess: boolean = false;

	tagOptions: string[] = []; //SelectItem[] = [];
	
	underCategory: UnderCategory;

	constructor(
		public activeModal: NgbActiveModal,
		private subCategoryService: SubCategoryService,
		private underCategoryService: UnderCategoryService,
		private messageService: MessageService
	) { }

	public onShow(under_category_id: number)
	{
		this.uiBlocked = true;
        
		this.form = new SubCategory;
		this.form.under_category_id = under_category_id;

		this.underCategoryService.getSingle(under_category_id).subscribe(response => {
			if(response.data) {
				this.underCategory = response.data;
			} else {
				this.activeModal.close("error");
			}
			this.uiBlocked = false;
		}, errorResponse => {
			console.error(errorResponse);
			this.activeModal.close("error");
			this.uiBlocked = false;
		});
	}

	public onSubmit()
	{
		this.uiBlocked = true;

		this.subCategoryService.update(this.form, true).subscribe(response => {
			if(response.success) {
				this.errors = {};
				this.messageService.add({severity: 'success', summary: 'Sub-categorie', detail: 'Sub-categorie is opgeslagen!'});
				this.activeModal.close("success");
			} else {
				this.messageService.add({severity: 'error', summary: 'Sub-categorie', detail: 'Sub-categorie kon niet worden opgeslagen!'});
			}
			this.uiBlocked = false;
		}, errorResponse => {
			if(errorResponse.error && errorResponse.error.errors) {
				this.errors = errorResponse.error.errors;
			}
			this.uiBlocked = false;
		});
	}

}

/**
 * Modal 
 */

@Component({
	selector: 'app-sub-category-modal',
	template: ``,
	//templateUrl: './upload-modal.component.html',
	//styleUrls: ['./upload-modal.component.scss'],
	animations: []
})
export class SubCategoryModalComponent implements OnInit {

	private modalRef: NgbModalRef;

	constructor(private modalService: NgbModal) { }

	ngOnInit()
	{

	}

	public show(under_category_id: number)
	{
		//console.log(this.componentRef);

        //this.modalService.open(this.content, {
		this.modalRef = this.modalService.open(SubCategoryModalContent ,{ 
            //size: 'lg',
            centered: true,
			ariaLabelledBy: 'modal-basic-title',
			backdrop: 'static'
		});
		
		this.modalRef.result.then((result) => {
            //this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});

		this.modalRef.componentInstance.onShow(under_category_id);
	}

	public hide()
	{
		this.modalRef.close();
	}

}