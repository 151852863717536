import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { ConfirmationService } from 'primeng/primeng';
import { TranslateService } from '@ngx-translate/core';

export interface CanComponentDeactivate {
	//canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
	canDeactivate: (guard: CanDeactivateGuard) => boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

    constructor(private confirmationService: ConfirmationService, private translate: TranslateService) { }

	canDeactivate(component: CanComponentDeactivate) {
		return component.canDeactivate ? component.canDeactivate(this) : true;
	}

	showConfirmDialog(header: string = 'Confirmation', message: string = 'Are you sure that you want to proceed?', icon: string = 'exclamation-circle'): Observable<boolean> {
		return Observable.create(observer => {
			this.translate.get([header, message]).subscribe(res => {
				this.confirmationService.confirm({
					message: res[message],
					header: res[header],
					icon: 'fa fa-' + icon,
					accept: () => {
						console.log('accept');
						observer.next(true);
						observer.complete();
					},
					reject: () => {
						console.log('reject');
						observer.next(false);
						observer.complete();
					}
				});
			});
		});
	}

}
