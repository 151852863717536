import { NgModule, LOCALE_ID, Injectable } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { Http, HttpModule } from '@angular/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard, AdminGuard, CanDeactivateGuard } from './shared';
import { SharedPipesModule } from './shared/pipes/shared-pipes.module';
import { ConfirmDialogModule, ConfirmationService } from 'primeng/primeng';
import { MessageService } from 'primeng/components/common/messageservice';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    // Change this to your upload POST address:
     //url: 'https://httpbin.org/post',
     maxFilesize: 16,
     acceptedFiles: 'image/*'
};

import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask/src/currency-mask.config";

import { CustomReuseStrategy } from './reuse-strategy';
import { CookieService } from "ngx-cookie-service";

import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl, 'nl');

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
    align: "right",
    allowNegative: false,
    allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "€ ",
    suffix: "",
    thousands: ""
};

import {
	AuthenticationService,
	UserService,
    TagService,
    PhotoService,
    PhotographerService,
    SubCategoryService,
    UnderCategoryService,
    MainCategoryService,
    VolumeService,
    SettingsService
} from './shared/services/index'

import { NgbDateParserFormatter, NgbDateAdapter,  NgbDateNativeAdapter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateNLParserFormatter } from "./shared/i18n/ngb-date-nl-parser-formatter";
import { NgbDateStringAdapter } from "./shared/i18n/ngb-date-string-adapter";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: Http) {
    // for development
    // return new TranslateHttpLoader(http, '/start-angular/SB-Admin-BS4-Angular-4/master/dist/assets/i18n/', '.json');
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        VirtualScrollerModule,
		FormsModule,
        HttpModule,
		HttpClientModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [Http]
            }
        }),
		SharedPipesModule,
        ConfirmDialogModule,
        CurrencyMaskModule,
        DropzoneModule
    ],
    providers: [
        AuthGuard,
        AdminGuard,
		CanDeactivateGuard,
		AuthenticationService,
		UserService,
        TagService,
        PhotoService,
        PhotographerService,
        SubCategoryService,
        UnderCategoryService,
        MainCategoryService,
        VolumeService,
		SettingsService,
		MessageService,
        ConfirmationService,
        CookieService,
		{ provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
		{ provide: LOCALE_ID, useValue: 'nl' },
        { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
        { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG },
		{ provide: NgbDateParserFormatter, useClass: NgbDateNLParserFormatter} ,
		{ provide: NgbDateAdapter, useClass: NgbDateStringAdapter }

	],
    bootstrap: [AppComponent]
})
export class AppModule {
}
