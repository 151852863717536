import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { trigger, transition, style, animate, state } from '@angular/animations'

import {
	SettingsService,
	AuthenticationService
} from './shared/services/index';


//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/forkJoin';
//import 'rxjs/add/observable/of';
//import 'rxjs/add/operator/delay';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
	animations: [
		trigger(
			'loadingOverlayAnimation',
			[
				transition(
					':leave', [
						style({'opacity': 1}),
						animate('500ms', style({'opacity': 0}))
					]
				)
			]
		)
	],
	providers: [
	]
})
export class AppComponent {

    get loaded(): boolean {
        return this.authService.showLoader;
    }

    constructor(
		private translate: TranslateService,
		private settingsService: SettingsService,
		private authService: AuthenticationService
	) {
		translate.addLangs(['nl']);
		translate.setDefaultLang('nl');
		translate.use('nl');
    }
}
