import { Volume } from './volume';
import { Photographer } from './photographer';
import { SubCategory } from './subCategory';
import { UnderCategory } from './underCategory';
import { MainCategory } from './mainCategory';
import { User } from './user';


export class Photo {
    id: number;
    photographer_id: number | null;
    sub_category_id: number | null;
    name: string;
    filename: string;
    description: string;
    comments: string;
    // obs_years: number[];
    // obs_editions: number[];
    // photo_taken_on: string;
    published: boolean;
    // published_on: string;
    user_uploaded_id: number;
    image_ext: string;
    // image_ratio: number; // Decimal
    image_width: number;
    image_height: number;
    image_type: string;
    image_size: number;
    image_md5: string;
    
    created_at: string;
    updated_at: string;

    tags: string[]; //tags: Tag[];
    publication_dates: string[];
    //volumes: number[]; // volumes: Volume[];
    volumes: VolumeItem[];

    photographer: Photographer;
    sub_category: SubCategory;
    under_category: UnderCategory;
    under_category_id: number;
    main_category: MainCategory;
    main_category_id: number;
    user_uploaded: User;

    temp_photo: string;

    get thumbnailUrl(): string {
        return this.getPhotoUrl(PhotoFormat.Thumbnail, 'jpg');
    }
    get hdUrl(): string {
        return this.getPhotoUrl(PhotoFormat.Hd);
    }
    get fullHdUrl(): string {
        return this.getPhotoUrl(PhotoFormat.Fullhd);
    }
    get originalUrl(): string {
        return this.getPhotoUrl(PhotoFormat.Original);
    }

    constructor() {
        this.id = -1;

        this.tags = [];
        this.publication_dates = [];
        this.volumes = [];
        this.photographer = new Photographer();
        this.sub_category = new SubCategory();
        this.under_category = new UnderCategory();
        this.main_category = new MainCategory();
        this.user_uploaded = new User();
    }

    public getPhotoUrl(format: PhotoFormat, extension: string = 'png', withLastModifiedTime: boolean = true)
    {
        //let base_url = "http://observant.dackus.it/foto/";
        let base_url = window.location.origin + "/foto/";
        //let lastmodtime = (withLastModifiedTime ? '?t=' + this.updated_at.replace(' ', '').replace('-', '') : '');
        let lastmodtime = (withLastModifiedTime ? '?t=' + (new Date(this.updated_at).getTime() / 1000) : '');
        
        return base_url + format + "/" + this.filename + "." + extension + lastmodtime;
    }
}

export enum PhotoFormat {
    Thumbnail = "thumbnail",
    Hd = "hd",
    Fullhd = "fullhd",
    Original = 'original'
}

export interface VolumeItem {
    id: number;
    edition: number;
}