import { Injectable } from '@angular/core';
import { ApiService, } from './api.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { retry } from 'rxjs/operators';

import { Settings } from '../models/index';
import { SelectItem } from '../../shared/models/index';

import { getSingleResponse, updateResponse } from './api.service';

import { ConfirmationService } from 'primeng/primeng';
import { MessageService } from 'primeng/components/common/messageservice';

@Injectable()
export class SettingsService extends ApiService<Settings> {

	protected apiName: string = 'settings';
	protected settings: Settings = new Settings;

	get base_url() { return this.settings.base_url };
	get offer_footer_text() { return this.settings.offer_footer_text };
	get default_classification() { return this.settings.default_classification };
	get default_pricelist() { return this.settings.default_pricelist };

	constructor(
		public http: HttpClient,
		public translate: TranslateService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService
	) {
		super(http, translate, confirmationService, messageService, Settings);
	}

	loadSettings(reload: boolean = false): Observable<Settings> {
		if(!reload && Object.keys(this.settings).length === 0 && this.settings.constructor === Object) {
			return  Observable.create(observer => {
				observer.next(this.settings);
				observer.complete();
			});
		}
		return this.retrieveSettings().pipe(map(response => {
			this.settings = response.data;
			return this.settings;
		}));
	}

	/**
	 * getSingle(id)
	 * Retrieve single object
	 */
	retrieveSettings(): Observable<getSingleResponse<Settings>> {
		return this.http.get<getSingleResponse<Settings>>(
			this.getBaseUrl('get')
		).pipe(retry(this.retries));
	}

	/**
	 *
	 */
	update(data: Settings): Observable<updateResponse<Settings>> {
		return this.http.post<updateResponse<Settings>>(
			this.getBaseUrl('update'),
			data
		);
	}

}
