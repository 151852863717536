import { Pipe, PipeTransform } from '@angular/core';
import { MainCategoryService } from '../services';

@Pipe({
	name: 'maincategory',
	pure: false
})
export class MainCategoryPipe implements PipeTransform {

	constructor(private mainCategoryService: MainCategoryService) { }

	transform(id: number): string {
		if(!id) {
			return '';
		}

		let maincat = this.mainCategoryService.getItem(id);

		if(maincat) {
			return maincat.name;
		}

		return '';
	}
}
