import { Injectable } from '@angular/core';
import { ApiService, updateResponse, } from './api.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Photo } from '../models/index';

import { ConfirmationService } from 'primeng/primeng';
import { MessageService } from 'primeng/components/common/messageservice';
import { TagService } from './tag.service';

@Injectable()
export class PhotoService extends ApiService<Photo> {

	protected apiName: string = 'photo';

	constructor(
		public http: HttpClient,
		public translate: TranslateService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		private tagService: TagService
	) {
		super(http, translate, confirmationService, messageService, Photo);
	}

	
	update(data: Photo, store: boolean = false): Observable<updateResponse<Photo>> {
		return super.update(data, store).pipe(map(data => {

			this.tagService.loadItems(true).subscribe(response => {});

			return data;
		}));
    }
    
    copy(id: number): Observable<copyResponse> {
        return this.http.get<copyResponse>(
			this.getBaseUrl('copy', id.toString())
		);
    }

}

export interface copyResponse {
	success: boolean;
    error: string;
    new_id: number;
}