import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '../services/index';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
		private router: Router,
		private authService: AuthenticationService) {
	}

	canActivate(): Observable<boolean> {
		return this.authService.isLoggedIn().pipe(map(auth => {

			if(!auth) {
				this.router.navigate(['/login']);
			}

			return auth;
		}));
	}

    /*canActivate() {
        if (localStorage.getItem('isLoggedin')) {
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }*/
}
