import { Injectable } from '@angular/core';
import { ApiService, } from './api.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/map'

import { Photographer } from '../models/index';
import { SelectItem } from '../../shared/models/index';

import { ConfirmationService } from 'primeng/primeng';
import { MessageService } from 'primeng/components/common/messageservice';

@Injectable()
export class PhotographerService extends ApiService<Photographer> {

	protected apiName: string = 'photographer';
	protected autoLoadItems = true;

	protected loadItemsParameters = {
		sortField: ['priority', 'name'],
		sortOrder: [1, 0]
	};

	constructor(
		public http: HttpClient,
		public translate: TranslateService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService
	) {
		super(http, translate, confirmationService, messageService, Photographer);
	}

	selectItemArray(): SelectItem[] {
		let array: SelectItem[] = [];

		for(var item of this.items) {
			array.push({
				label: item.name,
				value: item.id
			})
		}

		return array;
	}

}
