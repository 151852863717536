import { Injectable } from '@angular/core';
import { ApiService, } from './api.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Tag } from '../models/index';
import { SelectItem } from '../../shared/models/index';

import { ConfirmationService } from 'primeng/primeng';
import { MessageService } from 'primeng/components/common/messageservice';

import * as _ from 'lodash';

@Injectable()
export class TagService extends ApiService<Tag> {

	protected apiName: string = 'tag';
	protected autoLoadItems = true;

	protected loadItemsParameters = {
		sortField: 'name',
		sortOrder: 0
	};
	
	constructor(
		public http: HttpClient,
		public translate: TranslateService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService
	) {
		super(http, translate, confirmationService, messageService, Tag);
	}

	selectItemArray(items?: Tag[]): SelectItem[] {
		let array: SelectItem[] = [];

		if(!items) {
			items = this.items;
		}

		for(var item of items) {
			array.push({
				label: item.name,
				value: item.name, //item.id
			})
		}

		return array;
	}

}
