import { Pipe, PipeTransform } from '@angular/core';
import { PhotographerService } from '../services';

@Pipe({
	name: 'photographer',
	pure: false
})
export class PhotographerPipe implements PipeTransform {
	private cachedData: string = null;
    private cachedId = 0;

	constructor(private photographerService: PhotographerService) { }

	transform(id: number): string {
		if(!id) {
			return '';
		}

		let photographer = this.photographerService.getItem(id);

		if(photographer) {
			return photographer.name;
		}

		return '';

		/*if(id != this.cachedId) {
			this.cachedData = null;
			this.cachedId = id;
			this.photographerService.getItem(id);
			this.photographerService.getSingle(id).subscribe(photographer => {
				this.cachedData = photographer.data.name;
			})
		}

		return this.cachedData;*/
	}
}
